import React, {useEffect, useMemo, useRef, useState} from 'react'
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
	primary: {
		color: 'primary'
	},
	default: {
		color: 'default'
	}
})

export default function VideoCount (props) {
	const classes = useStyles()
	const [currentValue, setCurrentValue] = useState(0)
	const buttonValue = useRef(1)
	
	// console.log('props.data:')
	// console.log(props.data)
	const list = (value) => {
		let res = []
		for(let i = 0; i < value.length; i++){
			res.push(
				<div className="video-button-item" key={i+1}>
					<Button
						variant="contained"
						onClick={handleChangeButton}
						value={i+1}
						color={i+1 === Number(buttonValue.current) ? "primary" : "default"}
						>{i+1}
					</Button>
				</div>
			)
		}
		return res
	}
	
	const handleChangeButton = (e) => {
		e.preventDefault()
		// console.error('button被点击:')
		// console.log(e.currentTarget.value)
		buttonValue.current = e.currentTarget.value
		setCurrentValue(e.currentTarget.value)
		props.onChange(props.data[e.currentTarget.value-1])
	}
	
	return (
		<div className="number-box">
			{list(props.data)}
		</div>
	)
}