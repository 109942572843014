import React from "react";

class Img extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			imageUrl: this.props.imageUrl
		}
		
		this.handleImageLoaded = this.handleImageLoaded.bind(this)
		this.handleImageErrored = this.handleImageErrored.bind(this)
	}
	
	handleImageLoaded(){}
	
	handleImageErrored(){
		this.setState({
			imageUrl: this.props.defaultImg
		})
	}
	
	render() {
		return (
			<img src={this.state.imageUrl}
					 onLoad={this.handleImageLoaded}
					 onError={this.handleImageErrored}
			/>
		)
	}
}

export default Img