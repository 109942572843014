import React from "react"
import InfiniteScroll from 'react-infinite-scroller'
import {RandomRange} from "../../utils/Random";
import {encryptKey} from "../../utils/AESUtil";

class ChatRecord extends React.Component{
	constructor(props) {
		super(props);
		
		this.state = {
			hasMore: true,
			data: [],
			pageNum: 1,
			pageSize: 20,
		}
		
		this.getMore = this.getMore.bind(this)
		this.renderItemList = this.renderItemList.bind(this)
	}
	
	getMore = () => {
		const {data} = this.state
		let formData = new FormData()
		let random = RandomRange(16,16)
		formData.append('access_token', encryptKey(random))
		formData.append('iv', random)
		formData.append('id', this.props.roomId)
		formData.append('page', this.state.pageNum)
		formData.append('size', this.state.pageSize)
		fetch('https://atteacher.ixuesiwei.com/app/course/listPlaybackMsg',{
			method: 'post',
			body: formData
		})
			.then((res) => {
				return res.json()
			})
			.then((result) => {
				// console.log(result)
				if(result.code === 200){
					const arr = result.result.list
					if(arr.length > 0){
						let records = new Array()
						arr.map((chat) => {
							const str = chat.msg
							if( typeof str == 'string'){
								try {
									const obj = JSON.parse(str)
									if(typeof obj == 'object' && obj){
										if(obj.hasOwnProperty("message") ){
											let message = new Object()
											message.userId = chat.senderId
											message.name = chat.sender
											message.createTime = chat.createTime
											message.msg = obj.message
											records.push(message)
										}else if(obj.hasOwnProperty("cmd")){
											console.log('cmd command')
										}else{
											console.error(str)
										}
									}
								}catch (e) {
									console.log(e)
								}
							}
							
						})
						this.setState({
							pageNum: this.state.pageNum + 1,
							data: [...data, ...records],
							hasMore: true
						})
					} else {
						this.setState({
							hasMore: false
						})
						// let mes = new Array()
						// let m = new Object()
						// m.userId = "2010"
						// m.name = "学子1"
						// m.createTime = new Date().toDateString()
						// m.msg = "这是一条测试"
						// mes.push(m)
						// this.setState({
						// 	data: [...data, ...mes],
						// 	hasMore: false
						// })
					}
				}
			})
	}
	
	renderItemList = (value) => {
		let res =[]
		for(let i = 0; i < value.length; i++){
			res.push(
				<li key={i+1}>
					<div className="chat-record-item" >
						<span>{value[i].createTime}</span>
						<br/>
						<span>{value[i].name}:</span>
						<span>{value[i].msg}</span>
					</div>
				</li>
				
			)
		}
		return res
	}
	
	render() {
		const data = this.state.data;
		return (
			<div className="chat-record-box">
				<InfiniteScroll
					pageStart={1}
					loadMore={this.getMore}
					loader={
						<div className="loader" key={0}>
							Loading ...
						</div>
					}
					hasMore={this.state.hasMore}
					useWindow={false}
				>
					<ul>{this.renderItemList(data)}</ul>
				</InfiniteScroll>
			</div>
		)
	}
}

export default ChatRecord