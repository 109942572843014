import {useEffect} from "react";
import {useGlobalMutation, useGlobalState} from "./container";


export default function useMessage (client) {
	const stateCtx = useGlobalState()
	const mutationCtx = useGlobalMutation()
	
	const chatRecord = stateCtx.chatRecord
	
	useEffect(() => {
		if(client && client._subscribed === false){
			client.on('ConnectionStateChanged', mutationCtx.rtmConnectionStateChanged,null)
			client._subscribed = true
		}
		if(client && client._subscribedL === 'pending'){
			// client.on('ChannelMessage', mutationCtx.receiveMessage,stateCtx.config.channelName)
			client.on('ChannelMessage', (text,memberId) => {
				const str = text.text
				if( typeof str == 'string'){
					try {
						const obj = JSON.parse(str)
						if(typeof obj == 'object' && obj) {
							if(obj.hasOwnProperty("message") && obj.hasOwnProperty("userName")
								&& obj.hasOwnProperty("photo")){
								mutationCtx.receiveMessage(text.text,memberId)
								return;
							}
							if(obj.hasOwnProperty("cmd")){
								if(obj.cmd === 301){
									mutationCtx.updateConfig({permission: 1})
								} else if (obj.cmd === 302){
									mutationCtx.updateConfig({permission: 0})
								}
							}
						} else {
							console.error(text.text)
						}
					}catch (e) {
						console.log(e)
					}
				}
				// client.getUserAttributes(memberId)
					// 				// 	.then((attributes) =>{
					// 				// 		console.error(attributes)
					// 				// 		console.error(text)
					// 				// 		mutationCtx.receiveMessage(text.text,memberId,attributes)
					// 				// 	}
					// 				//
					// 				// 	)
					// 				// 	.catch(err => console.error(err))
			}
			,stateCtx.config.channelName)
			client.on('MemberCountUpdated', mutationCtx.updateMemberCount,stateCtx.config.channelName)
			client.on('MemberJoined', mutationCtx.addMember,stateCtx.config.channelName)
			client.on('MemberLeft', mutationCtx.removeMember,stateCtx.config.channelName)
			client._subscribedL = true
		}
	},[client, mutationCtx])
	
	return chatRecord
}