import CryptoJS from 'crypto-js'

const CRYPT_KEY = "y2W89L6BkRAFljhN"

export const encrypt = (iv,content) => {
	const key = CryptoJS.enc.Utf8.parse(CRYPT_KEY)
	const aseiv = CryptoJS.enc.Utf8.parse(iv)
	const encrypted = CryptoJS.AES.encrypt(content,key,{
		iv: aseiv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	})
	//base64密文
	return encrypted.toString()
}

export const encryptKey = (iv) => {
	return encrypt(iv,CRYPT_KEY)
}