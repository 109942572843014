import React, {useEffect, useMemo, useRef, useState} from "react";
import {useGlobalMutation} from "../../utils/container";

export default function ChatInput(props){
	const [message, setMessage] = useState('')
	const mutationCtx = useGlobalMutation()
	
	const placeholder = useRef('')
	const readonly = useRef('')
	// const sendDelay = useRef(0)
	const [num, setNum] = useState(0)
	const [isSend, setIsSend] = useState(false)
	//input变化
	const handleChange = (e) => {
		setMessage(e.target.value)
	}
	//点击提交或回车
	const handleClick = (e) => {
		e.preventDefault()
		if(!isSend){
			sendMessage()
		}
	}
	
	const handleKeyPress = (e) => {
		if(e.key === 'Enter'){
			if(!isSend){
				sendMessage()
			}
		}
		return false
	}
	
	
	const sendMessage = () => {
		if(props.permission === 0 || props.permission == null) {
			if(!message.match(/^\s*$/)) {
				let msgObj = {}
				msgObj.message = message
				msgObj.photo = props.photo
				msgObj.userName = props.nickName
				const str = JSON.stringify(msgObj)
				props.client.sendChannelMessage(str)
					.then(() => {
						// console.log('send message')
						// console.log(props)
						mutationCtx.sendLocalMessage(str, props.uid)
						setMessage('')
						
						setNum(5)
						setIsSend(true)
					})
					.catch((err) => {
						console.error(err)
					})
				// if(props.onMessageChange(message)) {
				// 	console.log('send message success')
				// 	setMessage('')
				// }else{
				// 	console.error('send message failed')
				// }
			}
		}
	}
	
		useMemo(() => {
			if(props.permission === 0 || props.permission == null){
				placeholder.current = "按回车发送"
				readonly.current = ""
			}else if(props.permission === 1){
				setMessage("")
				placeholder.current = "全频道已禁言！"
				readonly.current = "readonly"
			}
		},[props.permission])
		
	useEffect(() => {
		let timer = 0;
		if(isSend && num !== 0){
			timer = setInterval(() => {
				setNum(n => {
					if(n == 1) {
						setIsSend(false)
						clearInterval(timer)
					}
					return n - 1
				})
			}, 1000)
		}
		return () => {
			clearInterval(timer)
		}
	}, [isSend])
	
	return (
		<div className="bottom-area">
			<div className="input-box">
				<div className="input">
					{placeholder.current !== '' ?
						<textarea className="chat-send-text" maxLength={53} placeholder={placeholder.current} onKeyPress={handleKeyPress}
											onChange={handleChange} value={message} readOnly={readonly.current} />
						: null}
					{/*<textarea className="chat-send-text" maxLength={53} placeholder={placeholder.current} onKeyPress={handleKeyPress}*/}
					{/*	onChange={handleChange} value={message} readOnly={readonly.current}></textarea>*/}
					<div className="chat-send-button" onClick={handleClick}>发送</div>
				</div>
				
			</div>
		</div>
	)
}