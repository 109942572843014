import AgoraRTM from "agora-rtm-sdk"
import EventEmitter from 'events'
const appID = process.env.REACT_APP_AGORA_APP_ID

export default class RTMClient {
	constructor() {
		this._client = null
		this._logined = false
		this._channel = null
		this._joined = false
		this._uid = 0
		this._channelName = ''
		this._subscribed = false
		this._subscribedL = false
		this._created = false
	}
	
	createClient() {
		this._client = AgoraRTM.createInstance(appID)
		return this._client
	}
	
	on(evt, callback, channelName) {
		
		if(channelName !== null){
			this._channel.on(evt, callback)
			return
		}
		
		this._client.on(evt, callback)
	}
	
	join(data){
		this._joined = 'pending'
		return new Promise((resolve, reject) => {
			this._uid = data.uid
			this._channelName = data.channel
			this.login(data.rtmToken ? data.rtmToken : null, data.uid)
				.then( () => {
					this._logined = true
					this.joinChannel(data.channel)
						.then(() => {
							resolve()
							this._joined = true
						})
						.catch((err) => {
							this._joined = false
							reject(err)
							console.error('rtcChannel join failed', err)
						})
				})
				.catch((err) => {
					this._joined = false
					reject(err)
					console.error('rtcClient login failed', err)
				})
			
		})
	}
	
	login (token, uid) {
		this.uid = uid
		return  this._client.login({
			token: token,
			uid: uid
		})
	}
	
	async logout () {
		return this._client.logout()
	}
	
	async joinChannel(name){
		this._channel = this._client.createChannel(name)
		this._subscribedL = 'pending'
		return this._channel.join()
	}
	
	async leaveChannel(name) {
		if (!this._channel || (this._client && this._joined)) {
			return
		}
		return this._channel.leave()
	}
	
	async sendChannelMessage(text){
		if(!this._channel || !this._joined){
			return
		}
		return this._channel.sendMessage({ text },{enableHistoricalMessaging:true})
	}
	
	async sendPeerMessage(text, peerId) {
		return this._client.sendMessageToPeer({text}, peerId)
	}
	
	async queryPeerOnlineStatus(peerId){
		return this._client.queryPeerOnlineStatus([peerId])
	}
	
	setLocalUserAttributes(photo, name){
		return new Promise((resolve, reject) => {
			let attrsMap = new Object()
			attrsMap.photo=photo
			attrsMap.nickName=name
			this._client.setLocalUserAttributes(attrsMap)
				.then(() => {
					// console.log('rtmClient setAttributes success')
					// console.log('uid:'+ this._uid)
					// console.log(attrsMap)
					resolve(this._uid)
				})
				.catch((err) => {
					reject(err)
					console.error('rtmClient setAttributes failed', err)
				})
		})
	}
	
	getUserAttributes(uid){
		return new Promise((resolve, reject) => {
			this._client.getUserAttributes(uid)
				.then((attributes) => {
					resolve(attributes)
				})
				.catch((err) => {
					reject()
					console.error(err)
				})
		})
	}
	
	getMembers(){
		return new Promise(((resolve, reject) => {
			this._channel.getMembers()
				.then(members => {
					resolve(members)
				})
				.catch(err => {
					reject()
					console.error(err)
				})
		}))
	}
}
