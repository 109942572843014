import AgoraRTC from 'agora-rtc-sdk'
import EventEmitter from 'events'
const appID = process.env.REACT_APP_AGORA_APP_ID
console.log(
	'agora sdk version: ' +
	AgoraRTC.VERSION +
	' compatible: ' +
	AgoraRTC.checkSystemRequirements()
)

export default class RTCClient {
	constructor() {
		this._client = null
		this._joined = false
		this._published = false
		// this._enableBeauty = false;
		this._localStream = null
		this._remoteStreams = []
		this._params = {}
		this._uid = 0
		this._eventBus = new EventEmitter()
		this._showProfile = false
		this._subscribed = false
		this._created = false
		this._liveStreaming = false
		this._screenFlag = false
	}
	
	createClient(data) {
		this._client = AgoraRTC.createClient({
			mode: data.mode,
			codec: data.codec
		})
		return this._client
	}
	
	closeStream() {
		if (this._localStream.isPlaying()) {
			this._localStream.stop()
		}
		this._localStream.close()
		this._screenFlag = false
	}
	
	destory() {
		this._created = false
		this._client = null
	}
	
	setClientRole(role){
		this._client.setClientRole(role)
	}
	
	createRTCStream(data){
		return new Promise((resolve, reject) => {
			this._uid = this._localStream ? this._localStream.getId() : data.uid
			if(this._localStream){
				this.closeStream()
				this.unpublish()
			}
			
			const rtcStream = AgoraRTC.createStream({
				streamID: this._uid,
				audio: true,
				video: true,
				screen: false,
				microphoneId: data.microphoneId,
				cameraId: data.cameraId
			})
			
			if(data.resolution && data.resolution !== 'default'){
				rtcStream.setVideoProfile(data.resolution)
			}
			
			rtcStream.init(
				() => {
					this._localStream = rtcStream
					this._eventBus.emit('localStream-added',{
						stream: this._localStream
					})
					if(data.muteVideo === false){
						this._localStream.muteVideo()
					}
					if(data.muteAudio === false){
						this._localStream.muteAudio()
					}
					resolve()
				},
				(err) => {
					reject(err)
					console.error('init local stream failed ', err)
				}
			)
		})
	}
	//开启双流模式
	enableDualStream(){
		return new Promise((resolve, reject) => {
			this._client.enableDualStream(resolve,reject)
		})
	}
	//设置视频大小流
	setRemoteVideoStreamType(stream, streamType){
		this._client.setRemoteVideoStreamType(stream,streamType)
	}
	//订阅远端音视频流
	subscribe(stream, callback){
		this._client.subscribe(stream,callback)
	}
	// 设置音视频流回退策略
	setStreamFallbackOption(stream, type){
		this._client.setStreamFallbackOption(stream,type)
	}
	
	_createTmpStream() {
		this._uid = 0
		return new Promise((resolve, reject) => {
			if(this._localStream) {
				this._localStream.close()
			}
			const _tempStream = AgoraRTC.createStream({
				streamID: this._uid,
				audio: true,
				video: true,
				screen: false
			})
			
			_tempStream.init(
				() => {
					this._localStream = _tempStream
					resolve()
				},
				(err) => {
					reject(err)
					console.error('init local stream failed ', err)
				}
			)
		})
	}
	// 枚举可用的媒体输入/输出设备
	getDevices(){
		return new Promise((resolve, reject) => {
			if(!this._client){
				this.createClient({mode:'live', codec:'vp8'})
			}
			this._createTmpStream().then(() => {
				AgoraRTC.getDevices((devices) => {
					this._localStream.close()
					resolve(devices)
				})
			})
		})
	}
	
	on(evt, callback) {
		const customEvent = ['localStream-added', 'screenShare-canceled']
		
		if (customEvent.indexOf(evt) !== -1) {
			this._eventBus.on(evt, callback)
			return
		}
		
		this._client.on(evt, callback)
	}
	
	createScreenSharingStream(data) {
		return new Promise((resolve, reject) => {
			//create screen sharing stream
			this._uid = this._localStream ? this._localStream.getId() : data.uid
			if (this._localStream) {
				this._uid = this._localStream.getId()
				this.unpublish()
			}
			
			const streamSpec = {
				streamID: this._uid,
				audio: false,
				video: false,
				screen: true,
				screenAudio:true,
				microphoneId: data.microphoneId,
				cameraId: data.cameraId
			}
			
			const screenSharingStream = AgoraRTC.createStream(streamSpec)
			if (data.resolution && data.resolution !== 'default') {
				screenSharingStream.setScreenProfile(`${data.resolution}_2`)
				console.error()
			}
			
			screenSharingStream.on('stopScreenSharing', (evt) => {
				this._eventBus.emit('stopScreenSharing', evt)
				this.closeStream()
				this.unpublish()
			})

			// init local stream
			screenSharingStream.init(
				() => {
					if(this._localStream){
						this.closeStream()
					}
					
					this._localStream = screenSharingStream
					// run callback
					this._eventBus.emit('localStream-added', {
						stream: this._localStream
					})
					this._screenFlag = true
					
					resolve()
				},
				(err) => {
					// this.publish()
					reject(err)
				}
			)
		})
	}
	
	replaceTrack(data){
		return new Promise((resolve, reject) => {
			console.log('localStream')
			console.log(this._localStream)
			if(!this._screenFlag){
				reject()
			}else{
				const streamSpec = {
					audio: false,
					video: false,
					screen: true,
					screenAudio:true,
				}
				
				const newStream = AgoraRTC.createStream(streamSpec)
				if (data.resolution && data.resolution !== 'default') {
					newStream.setScreenProfile(`${data.resolution}_2`)
				}
				
				newStream.on('stopScreenSharing', (evt) => {
					this._eventBus.emit('stopScreenSharing', evt)
					this.closeStream()
					this.unpublish()
				})
				
				newStream.init(
					() => {
						const newVideoTrack = newStream.getVideoTrack()
						const newAudioTrack = newStream.getAudioTrack()
						// console.log('newVideoTrack:')
						// console.error(newVideoTrack)
						// console.log('newAudioTrack')
						// console.error(newAudioTrack)
						// const currentAudio = this._localStream.getAudioTrack()
						// console.log('localAudioTrack:')
						// console.error(currentAudio)
						let audioTrack = newAudioTrack
						if(audioTrack == null){
							const currentStream = this._localStream
							navigator.mediaDevices.getUserMedia(
								{video: false, audio: true}
							)
								.then(function (mediaStream) {
									// console.log('navigator:')
									// console.error(mediaStream.getAudioTracks()[0])
									// console.log('mediaStream:')
									// console.error(mediaStream.getAudioTracks())
									audioTrack = mediaStream.getAudioTracks()[0]
									
									currentStream.replaceTrack(newVideoTrack,
										() => {
										// console.log('currentStream:')
										// console.error(currentStream)
										// 	console.log('currentAudio:')
										// console.error(currentStream.getAudioTrack())
											if(currentStream.getAudioTrack() == null){
												currentStream.addTrack(audioTrack,
													() => {
														resolve()
													},
													(err) => {
														reject(err)
													})
											}else{
												currentStream.replaceTrack(audioTrack,
													() => {
														resolve()
													},
													(err) => {
														reject(err)
													})
											}
											
										},
										(err) => {
											reject(err)
										})
								})
						} else {
							this._localStream.replaceTrack(newVideoTrack,
								() => {
									// console.log('audioTrack:')
									// console.error(audioTrack)
									this._localStream.replaceTrack(audioTrack,
										() => {
											// this._lastAudioTrack = null
											resolve()
										},
										(err) => {
											reject(err)
										})
								},
								(err) => {
									reject(err)
								})
						}
						
					},
					(err) => {
						reject(err)
					}
				)
			}
			
		})
	}
	
	changeScreenAudio(data){
		return new Promise((resolve, reject) => {
			const streamSpec = {
				audio: true,
				video: true,
				screen: false,
				screenAudio: false,
				microphoneId: data.microphoneId,
				cameraId: data.cameraId
			}
			const currentStream = this._localStream
			
			console.log(currentStream.getAudioTrack())

			navigator.mediaDevices.getUserMedia(
				{video: false, audio: true}
			)
				.then(function(mediaStream){
					console.log('userMedia:')
					console.error(mediaStream.getAudioTracks())
					// console.error(mediaStream.getVideoTracks()[0])
					
					const newStream = AgoraRTC.createStream({
						video: false,
						audio: true,
						audioSource: mediaStream.getAudioTracks()[0],
						// videoSource: mediaStream.getVideoTracks()[0]
					})
					
					newStream.init(
						() => {
							// const newAudioTrack = newStream.getAudioTrack()
							// console.error(newAudioTrack)
							// this._localStream.removeTrack(this._localStream.getAudioTrack())
							console.error()
							// currentStream.removeTrack(currentStream.getAudioTrack())
							// currentStream.addTrack(newStream.getAudioTrack())
							currentStream.replaceTrack(newStream.getAudioTrack())
							// currentStream.removeTrack(currentStream.getVideoTrack())
							// currentStream.addTrack(newStream.getVideoTrack())
							// this._localStream.addTrack(newAudioTrack,
							// 	() => {
							resolve()
							// },
							// (err) => {
							// 	reject('Here!audio:'+err)
							// })
						}
					)
				})
				.catch((err) => {
					console.error('something wrong!'+err)
				})
			
			
			
		})
	}
	
	join(data){
		this._joined = 'pending'
		return new Promise((resolve, reject) => {
			this._params = data
			this._client.init(
				appID,
				() => {
					this._client.join(data.rtcToken ? data.rtcToken : null,data.channel,data.uid ? +data.uid : null,
						(uid) => {
							this._uid = uid
							console.log('join channel: ' + data.channel + ' success, uid: ' + uid)
							this._joined = true
							
							data.uid = uid
							resolve(data.uid)
							// if(data.host){
							// 	this.createRTCStream(data)
							// 		.then(() => {
							// 			this.enableDualStream()
							// 				.then(() => {
							// 					this.setRemoteVideoStreamType(this._localStream,0)
							// 					resolve(data.uid)
							// 				})
							// 				.catch((err) => {
							// 					reject(err)
							// 				})
							// 		})
							// 		.catch((err) => {
							// 			reject(err)
							// 		})
							// } else {
							// 	resolve()
							// }
							
						},
						(err) => {
							this._joined = false
							reject(err)
							console.error('client join failed', err)
						}
					)
				},
				(err) => {
					this._joined = false
					reject(err)
					console.error(err)
				}
			)
		})
	}
	
	addMediaDevicesAudio(){
		return new Promise((resolve, reject) => {
			if(!this._screenFlag){
				reject()
			}else if(this._localStream.getAudioTrack() != null){
				resolve()
			}else{
				const currentStream = this._localStream
				navigator.mediaDevices.getUserMedia({video: false, audio: true})
					.then(function (mediaStream) {
						currentStream.addTrack(mediaStream.getAudioTracks()[0])
						resolve()
					})
					.catch((err) => {
						reject(err)
					})
			}
		})
		
	}
	
	publish() {
	
			if (!this._client) {
				return
			}
			if (this._published) {
				return
			}
			const oldState = this._published
			
			//publish localStream
			this._client.publish(this._localStream, (err) => {
				this._published = oldState
				console.error(err)
				
			})
			this._published = true
		
		
	}
	
	unpublish() {
		if (!this._client) {
			return
		}
		if (!this._published) {
			return
		}
		const oldState = this._published
		
		//unpublish localStream
		this._client.unpublish(this._localStream, (err) => {
			this._published = oldState
			console.error(err)
		})
		this._published = false
	}
	
	leave() {
		return new Promise(resolve => {
			if (!this._client) {
				return resolve()
			}
			if (!this._joined) {
				return resolve()
			}
			//leave channel
			this._client.leave(() => {
					//close stream
					this._localStream.close()
					this._localStream.stop()
					while (this._remoteStreams.length > 0) {
						const stream = this._remoteStreams.shift()
						// const id = stream.getId()
						stream.stop()
						// removeView(id)
					}
					this._localStream = null
					this._remoteStreams = []
					this._published = false
					this._joined = false
					this.destory()
					// if (this._localStream && this._enableBeauty) {
					//   this._localStream.setBeautyEffectOptions(false);
					// }
					resolve()
				},
				(err) => {
					console.log('channel leave failed')
					console.error(err)
				}
			)
		})
	}
	// 新建直播流
	startLiveStreaming(){
		if(!this._client){
			return
		}
		const uid = this._params._uid
		const liveTranscoding = {
			'180p': {
				width: 320,
				height: 180,
				videoBitrate: 140,
				videoFramerate: 15,
				lowLatency: false,
				audioSampleRate: AgoraRTC.AUDIO_SAMPLE_RATE_48000,
				audioBitrate: 48,
				audioChannels: 1,
				videoGop: 30,
				videoCodecProfile: AgoraRTC.VIDEO_CODEC_PROFILE_HIGH,
				userCount: 1,
				backgroundColor: 0x000000,
				transcodingUsers: [{
					uid,
					alph: 1,
					width: 320 / 2,
					height: 180,
					zOrder: 1,
					x: 0,
					y: 0
				}],
			},
			'360p': {
				width: 640,
				height: 360,
				videoBitrate: 400,
				videoFramerate: 30,
				lowLatency: false,
				audioSampleRate: AgoraRTC.AUDIO_SAMPLE_RATE_48000,
				audioBitrate: 48,
				audioChannels: 1,
				videoGop: 30,
				videoCodecProfile: AgoraRTC.VIDEO_CODEC_PROFILE_HIGH,
				userCount: 1,
				backgroundColor: 0x000000,
				transcodingUsers: [{
					uid,
					alpha: 1,
					width: 640 / 2,
					height: 360,
					zOrder: 1,
					x: 0,
					y: 0
				}],
			},
			'720p': {
				width: 1280,
				height: 720,
				videoBitrate: 1130,
				videoFramerate: 24,
				lowLatency: false,
				audioSampleRate: AgoraRTC.AUDIO_SAMPLE_RATE_48000,
				audioBitrate: 48,
				audioChannels: 1,
				videoGop: 30,
				videoCodecProfile: AgoraRTC.VIDEO_CODEC_PROFILE_HIGH,
				userCount: 1,
				backgroundColor: 0x000000,
				transcodingUsers: [{
					uid,
					alpha: 1,
					width: 1280 / 2,
					height: 720,
					zOrder: 1,
					x: 0,
					y: 0
				}],
			}
		}
		// const transcodingConfig = liveTranscoding['720']
		// this.setLiveTranscoding(transcodingConfig)
		// console.error("startLiveStreaming will start!")
		this._client.startLiveStreaming(this._params.url,false)
		// console.error('url: '+this._params.url)
	}
	
	setLiveTranscoding(transcodingConfig){
		this._client.setLiveTranscoding(transcodingConfig);
	}
	// 更新直播流
	updateLiveTranscoding(resolution){
		if(!this._liveStreaming){
			return
		}
		const uid = this._params._uid
		const liveTranscoding = {
			'180p': {
				width: 320,
				height: 180,
				videoBitrate: 140,
				videoFramerate: 15,
				lowLatency: false,
				audioSampleRate: AgoraRTC.AUDIO_SAMPLE_RATE_48000,
				audioBitrate: 48,
				audioChannels: 1,
				videoGop: 30,
				videoCodecProfile: AgoraRTC.VIDEO_CODEC_PROFILE_HIGH,
				userCount: 1,
				backgroundColor: 0x000000,
				transcodingUsers: [{
					uid,
					alpha: 1,
					width: 320 / 2,
					height: 180,
					zOrder: 1,
					x: 0,
					y: 0
				}],
			},
			'360p': {
				width: 640,
				height: 360,
				videoBitrate: 400,
				videoFramerate: 30,
				lowLatency: false,
				audioSampleRate: AgoraRTC.AUDIO_SAMPLE_RATE_48000,
				audioBitrate: 48,
				audioChannels: 1,
				videoGop: 30,
				videoCodecProfile: AgoraRTC.VIDEO_CODEC_PROFILE_HIGH,
				userCount: 1,
				backgroundColor: 0x000000,
				transcodingUsers: [{
					uid,
					alpha: 1,
					width: 640 / 2,
					height: 360,
					zOrder: 1,
					x: 0,
					y: 0
				}],
			},
			'720p': {
				width: 1280,
				height: 720,
				videoBitrate: 1130,
				videoFramerate: 24,
				lowLatency: false,
				audioSampleRate: AgoraRTC.AUDIO_SAMPLE_RATE_48000,
				audioBitrate: 48,
				audioChannels: 1,
				videoGop: 30,
				videoCodecProfile: AgoraRTC.VIDEO_CODEC_PROFILE_HIGH,
				userCount: 1,
				backgroundColor: 0x000000,
				transcodingUsers: [{
					uid,
					alpha: 1,
					width: 1280 / 2,
					height: 720,
					zOrder: 1,
					x: 0,
					y: 0
				}],
			}
		}
		const transcodingConfig = liveTranscoding[resolution || this._params.resolution]
		console.log('setLiveTranscoding', transcodingConfig)
		this.setLiveTranscoding(transcodingConfig)
		this._client.stopLiveStreaming(this._params.url)
		this._client.startLiveStreaming(this._params.url,true)
	}
	//删除直播流
	stopLiveStreaming(){
		return new Promise(resolve => {
			if(!this._client){
				return resolve()
			}
			this._client.stopLiveStreaming(this._params.url)
			resolve()
		})
		
	}
}