import React from "react";
import videojs from 'video.js'
import VideoJsForReact from 'videojs-for-react'

export default class VideoPlayer extends React.Component {
	constructor(props) {
		super(props)
		
		this.state = {
			videoJsOptions: {
				preload: 'auto',
				// bigPlayButton: {},
				autoplay: true,
				controls: true,
				// width: 1280,
				// height: 720,
				fluid: true,
				// playbackRates: [1, 1.5, 2],
				sources: [
					{
						src: this.props.src,
						// type: 'application/x-mpegURL',
						// label: 'HLS',
						// withCredentials: false,
						// res: 1080
					},
					// {
					// 	src: 'https://blz-videos.nosdn.127.net/1/HearthStone/f6cd63b590d416821d3e27e0.mp4',
					// 	// type: 'application/x-mpegURL',
					// 	label: '1',
					// },
					// {
					// 	src: "http://vjs.zencdn.net/v/oceans.mp4",
					// 	label: '2'
					// },
					// {
					// 	src: "http://vjs.zencdn.net/v/oceans.mp4",
					// 	label: '3'
					// },
				]
			}
		}
	}
	
	render() {
		return (
			<div className="live-player">
				<VideoJsForReact
					sourcechanged={(player) => console.log(player)}
					onReady={(player) => console.log('准备完毕',player)}
					{...this.state.videoJsOptions}/>
			</div>
		)
	}
	
	// componentDidMount() {
	// 	const hls = {
	// 		autoplay: true,
	// 		controls: true,
	// 		sources: [{
	// 			src: this.props.src,
	// 			type: 'application/x-mpegURL'
	// 		}]
	// 	};
	// 	console.log(hls)
	// 	this.player = videojs(this.videoNode, hls, function onPlayerReady() {
	// 		console.log('onPlayerReady', this)
	// 	});
	// 	this.player.play()
	// }
	//
	// componentWillUnmount() {
	// 	if(this.player) {
	// 		this.player.dispose()
	// 	}
	// }
	//
	// render() {
	// 	return (
	// 		<div>
	// 			<div data-vjs-player>
	// 				<video ref={node => this.videoNode = node} className="video-js vjs-default-skin video" autoPlay="autoPlay"></video>
	// 			</div>
	// 		</div>
	// 	)
	// }
}