import React, {useEffect, useMemo, useRef, useState} from "react";
import useRouter from "../utils/use-router";
import {useGlobalMutation, useGlobalState} from "../utils/container";
import RTCClient from "../utils/rtc-client";
import useStream from "../utils/use-stream";
import StreamPlayer from "./room/StreamPlayer";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from 'clsx'
import AgoraRTC from 'agora-rtc-sdk'
import { RandomRange } from '../utils/Random'
import { encryptKey } from '../utils/AESUtil'
import Container from "@material-ui/core/Container";
import Img from "../utils/image";
import imgURL from '../assets/avatar.png'
import TabContainer from "./room/TabContainer";
import useMessage from "../utils/use-message";
import RTMClient from "../utils/rtm-client";
import ChatInput from "./room/ChatInput";

const useStyles = makeStyles({
	menu: {
		height: '150px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	customBtn: {
		width: '50px',
		height: '50px',
		marginLeft: '20px',
		borderRadius: '26px',
		backgroundColor: 'rgba(0, 0, 0, 0.4)',
		backgroundSize: '50px',
		cursor: 'pointer'
	},
	leftAlign: {
		display: 'flex',
		flex: '1',
		justifyContent: 'space-evenly'
	},
	rightAlign: {
		display: 'flex',
		flex: '1',
		justifyContent: 'center'
	},
	menuContainer: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		zIndex: '2'
	},
	
})

const RoomPage = (props) => {
	const classes = useStyles()
	const routerCtx = useRouter()
	const stateCtx = useGlobalState()
	const mutationCtx = useGlobalMutation()
	
	const localClient = useMemo(() => {
		// console.error('开启日志')
		// AgoraRTC.Logger.enableLogUpload();
		// AgoraRTC.Logger.setLogLevel(AgoraRTC.Logger.INFO);
		// console.error('日志上传已开启...')
		const client = new RTCClient()
		if(!client._created){
			client.createClient( {mode: stateCtx.mode, codec: stateCtx.codec})
			client._created = true
		}
		
		client.on('stream-published', (evt) => {
			localClient.startLiveStreaming()
			// console.error("startLiveStreaming ing...")
		})
		client.on('stream-unpublished', (evt) => {
			mutationCtx.setScreen(false)
		})
		return client
	}, [stateCtx.mode, stateCtx.codec])
	
	const rtmClient = useMemo(() => {
		const client = new RTMClient()
		if(!client._created){
			client.createClient()
			client._created = true
			// mutationCtx.setRTMClient(client)
		}
		return client
	},[])
	
	const [localStream, currentStream] = useStream(localClient)
	const [muteVideo, setMuteVideo] = useState(stateCtx.muteVideo)
	const [muteAudio, setMuteAudio] = useState(stateCtx.muteAudio)
	
	// const [query, setQuery] = useState('');
	// const [nickName, setNickName] = useState('')
	// const [photo, setPhoto] = useState('')
	const nickName = useRef('')
	const photo = useRef('')
	
	const chatRecord = useMessage(rtmClient)
	
	const config = useMemo(() => {
		return {
			// token: stateCtx.config.token,
			rtcToken: stateCtx.config.rtcToken,
			rtmToken: stateCtx.config.rtmToken,
			channel: stateCtx.config.channelName,
			microphoneId: stateCtx.config.microphoneId,
			cameraId: stateCtx.config.cameraId,
			resolution: stateCtx.config.resolution,
			muteVideo: muteVideo,
			muteAudio: muteAudio,
			uid: stateCtx.config.uid,
			host: stateCtx.config.host,
			url: stateCtx.config.url,
			earlierTime: stateCtx.earlierTime,
			permission: stateCtx.config.permission,
		}
	}, [stateCtx, muteVideo, muteAudio])
	// console.error(stateCtx)
	// console.error(config)
	
	useEffect(() => {
		return () => {
			localClient && localClient.leave(() => mutationCtx.clearAllStream())
		}
	}, [localClient])
	
	const history = routerCtx.history
	// console.error(routerCtx)
	const params = new URLSearchParams(window.location.search)
	// console.error(props)
	useMemo(() => {
		let query = props.location.search
		if(query.indexOf('agoraRoomId') !== -1
			&& query.indexOf('userId') !== -1
			&& query.indexOf('cataId') !== -1) {
			const arr = query.split('&')
			
			// const agoraRoomId = arr[0].split('=')[1]
			//
			// const userId = arr[1].split('=')[1]
			// const cataId = arr[2].split('=')[1]
			for(let j = 0; j < arr.length; j++){
				if(arr[j].indexOf('agoraRoomId') !== -1){
					var agoraRoomId = arr[j].split('=')[1]
					// console.error(agoraRoomId)
					continue
				}
				if(arr[j].indexOf('cataId') !== -1){
					var cataId = arr[j].split('=')[1]
					// console.error(cataId)
					continue
				}
				if(arr[j].indexOf('userId') !== -1){
					var userId = arr[j].split('=')[1]
					// console.error(userId)
					continue
				}
			}
			
			let formData = new FormData()
			let random = RandomRange(16,16)
			formData.append('access_token',encryptKey(random));
			formData.append('iv', random);
			formData.append('id', agoraRoomId);
			formData.append('userId', userId);
			formData.append('userType', '102');
			formData.append('expire', '7200');//失效时间（秒）
			fetch('https://atteacher.ixuesiwei.com/app/course/getCourseAccess',{
				method: 'post',
				body:formData
			})
				.then((res) => {
					return res.json()
				})
				.then((result) => {
					if(result.code === 200) {

						mutationCtx.updateConfig({
							url: result.result.urlPush,
							uid: result.result.token.userId,
							channelName: result.result.token.channelName,
							rtcToken: result.result.token.rtcToken,
							rtmToken: result.result.token.rtmToken,
							permission: result.result.permission,
						})

						let formData = new FormData()
						let random = RandomRange(16,16)
						formData.append('access_token',encryptKey(random));
						formData.append('iv', random);
						formData.append('id',userId)
						fetch('https://atteacher.ixuesiwei.com/app/course/getTeaInfo',{
							method: 'post',
							body:formData
						})
							.then((res) => {
								return res.json()
							})
							.then((result) => {
								// console.error(result)
								// console.error(result.result.nickName)
								let ava = result.result.photo
								const name = result.result.nickName
								nickName.current = name
								// console.error(result.result.photo)
								if( ava.indexOf("http") === -1 ){
									ava = 'https://rescdn.ixuesiwei.com' + ava
								}
								photo.current=ava
								// console.log('photo:'+ava)
								// console.log('nickName:' + name)
							})
							.catch((err) => {
								console.error(err)
							})
					} else if(result.code === 500){
						throw new Error(result.message)
					}
				})
				.catch((err) => {
					console.error(err)
				})
		}
	}, [])
	
	useEffect(() => {
		const roleParams = params.get('role')
		if(!config.channel && roleParams !== 'audience'){
			// history.push('/')
		}
	}, [config.channel, history, params])
	
	useEffect(() => {
		if(config.channel && localClient._created && localClient._joined === false){
			// console.error('这里是config'+config.uid)
			localClient
				.join(config)
				.then((uid) => {
					if(config.host) {
						localClient.publish()
					}
					mutationCtx.updateConfig({uid})
					mutationCtx.stopLoading()
				})
				.catch((err) => {
					// mutationCtx.toastError(`Media ${err.info}`)
					// routerCtx.history.push('/')
				})
		}
	}, [localClient, mutationCtx, config, routerCtx])
	
	useEffect(() => {
		if(config.channel && rtmClient._created && rtmClient._joined === false){
			rtmClient
				.join(config)
				.then(() => {
					// rtmClient.setLocalUserAttributes(photo.current,nickName.current)
					// 	.then((uid) => {
					// 		// rtmClient.getUserAttributes(uid)
					// 		// 	.then((attrs) => {
					// 		// 		console.log(attrs)
					// 		// 	})
					// 		console.log(uid+' set attributes success')
					// 	})
					// 	.catch((err) => {
					// 		// mutationCtx.toastError(err)
					// 		console.error(err)
					// 	})
					mutationCtx.stopLoading()
				})
				.catch((err) => {
					console.error(err)
				})
		}
	},[rtmClient, config, routerCtx])
	
	const handleClick = (name) => {
		return (evt) => {
			evt.stopPropagation()
			switch (name) {
				case 'video': {
					muteVideo ? localStream.muteVideo() : localStream.unmuteVideo()
					setMuteVideo(!muteVideo)
					break
				}
				case 'audio': {
					muteAudio ? localStream.muteAudio() : localStream.unmuteAudio()
					setMuteAudio(!muteAudio)
					break
				}
				case 'screen': {
					if(stateCtx.screen) {
						// localClient
						// 	.createRTCStream({
						// 		token: null,
						// 		channel: stateCtx.config.channelName,
						// 		microphoneId: stateCtx.config.microphoneId,
						// 		resolution: stateCtx.config.resolution,
						// 		muteVideo: muteVideo,
						// 		muteAudio: muteAudio,
						// 		// beauty: stateCtx.beauty
						// 	})
						// 	.then(() => {
						// 		localClient.publish()
						// 		mutationCtx.setScreen(false)
						// 	})
						// 	.catch((err) => {
						// 		mutationCtx.toastError('Media ${err.info}')
						// 		// routerCtx.history.push('/')
						// 	})
						localClient.stopLiveStreaming()
							.then(() => {
								localClient.closeStream()
								localClient.unpublish()
								mutationCtx.setScreen(false)
							})
							.catch((err) => {
								console.error(err)
								mutationCtx.toastError(`Media ${err.info}`)
							})
					} else {
						localClient
							.createScreenSharingStream({
								channel: stateCtx.config.channelName,
								microphoneId: stateCtx.config.microphoneId,
								cameraId: stateCtx.config.cameraId,
								resolution: stateCtx.config.resolution
							})
							.then(() => {
								localClient.addMediaDevicesAudio()
							})
							.then(() => {
								localClient.publish()
								// console.error("publish success!")
								mutationCtx.setScreen(true)
								setMuteVideo(true)
								setMuteAudio(true)
								// console.log(localClient)
								// console.log(stateCtx.config.resolution)
							})
							.catch((err) => {
								console.log(err)
								if(err.info === undefined) mutationCtx.toastError('Media '+err)
								else mutationCtx.toastError(`Media ${err.info}`)
							})
					}
					break
				}
				case 'replace':{
					localClient.replaceTrack({
						channel: stateCtx.config.channelName,
						microphoneId: stateCtx.config.microphoneId,
						cameraId: stateCtx.config.cameraId,
						resolution: stateCtx.config.resolution
					})
						.catch((err) => {
							console.log(err)
							mutationCtx.toastError(`Media ${err.info}`)
						})
					break
				}
				case 'change':{
					localClient.changeScreenAudio({
						microphoneId: stateCtx.config.microphoneId,
						cameraId: stateCtx.config.cameraId,
					})
						.catch((err) => {
							console.error(err)
							mutationCtx.toastError(`Media ${err.info}`)
						})
					break
				}
				case 'profile': {
					break
				}
				default:
					throw new Error('Unknown click handler, name: ${name}')
			}
		}
	}
	
	const handleDoubleClick = (stream) => {
		mutationCtx.setCurrentStream(stream)
	}
	
	const changeEnabled = (value) => {
		mutationCtx.setEarlierTime({
			earlierTime: value
		})
	}
	
	// const handleMessageChange = (text) => {
	// 	if(rtmClient._joined === true){
	// 		console.log('in')
	// 		console.log(text)
	// 		rtmClient.sendChannelMessage(text)
	// 			.then(() => {
	// 				console.log('success')
	// 				return true
	// 			})
	// 			.catch((err) => {
	// 				//消息发送失败
	// 				console.error(err)
	// 				return false
	// 			})
	// 	}else{
	// 		return false
	// 	}
	// }
	
	const otherStreams = useMemo(() => {
		return stateCtx.streams.filter(
			(it) => it.getId() !== currentStream.getId()
		)
	}, [stateCtx.streams, currentStream])

	const nick_name = useMemo(() => {
		return () => nickName.current
	}, [nickName])

	const image_photo = useMemo(() =>
	{
		return () => photo.current
	}, [photo])
	return (
		<div className="room">
			<div className="layout-header"></div>
			<div className="layout-player">
				<div className="layout-player-main">
					<div className="current-view">
						<div className="nav">
							<div className="avatar-container">
								<div className="default-avatar">
									<Img imageUrl={image_photo()} defaultImg={imgURL} />
								</div>
								<div className="avatar-text">{nick_name()}</div>
								{/*<div className="like"></div>*/}
							</div>
							{/*<Tooltip title="start">*/}
							{/*	<div*/}
							{/*		className="quit"*/}
							{/*		onClick={() => {*/}
							{/*			// localClient.leave().then(() => {*/}
							{/*			// 	mutationCtx.clearAllStream()*/}
							{/*			// 	// mutationCtx.resetState()*/}
							{/*			// 	// routerCtx.history.push('/')*/}
							{/*			// })*/}
							{/*			*/}
							{/*		}}*/}
							{/*	></div>*/}
							{/*</Tooltip>*/}
						</div>
						{currentStream ? (
							<StreamPlayer
								className={'main-stream-profile'}
								showProfile={stateCtx.profile}
								local={
									config.host
										? currentStream &&
										localStream &&
										currentStream.getId() === localStream.getId()
										: false
								}
								stream={currentStream}
								onDoubleClick={handleDoubleClick}
								uid={currentStream.getId()}
								domId={`stream-player-${currentStream.getId()}`}
							>
							</StreamPlayer>
						) : null}
						<div className={classes.menuContainer}>
							{config.host && (
								<div className={classes.menu}>
									<Tooltip title={stateCtx.screen ? 'stop-screen-share' : 'start-screen-share'}>
										<i
											onClick={handleClick('screen')}
											className={clsx(
												classes.customBtn,
												stateCtx.screen ? 'start-screen-share' : 'stop-screen-share'
											)}
										/>
									</Tooltip>
									<Tooltip title={muteVideo ? 'mute-video' : 'unmute-video'}>
										<i
											onClick={handleClick('video')}
											className={clsx(
												classes.customBtn,
												muteVideo ? 'mute-video' : 'unmute-video'
											)}
										/>
									</Tooltip>
									<Tooltip title={muteAudio ? 'mute-audio' : 'unmute-audio'}>
										<i
											onClick={handleClick('audio')}
											className={clsx(
												classes.customBtn,
												muteAudio ? 'mute-audio' : 'unmute-audio'
											)}
										/>
									</Tooltip>
									<Tooltip title={'replace'}>
										<i
											onClick={handleClick('replace')}
											className={clsx(
												classes.customBtn,
												'replace-screen-share'
											)}
										/>
									</Tooltip>
									{/*<Tooltip title={'change'}>*/}
									{/*	<i*/}
									{/*		onClick={handleClick('change')}*/}
									{/*		className={clsx(*/}
									{/*			classes.customBtn,*/}
									{/*			'change-screen-share'*/}
									{/*		)}*/}
									{/*	/>*/}
									{/*</Tooltip>*/}
								</div>
							)}
						</div>
						{/*<div className="stream-container">*/}
						{/*	{stateCtx.otherStreams.map((stream, index) => (*/}
						{/*		<StreamPlayer*/}
						{/*			className={'stream-profile'}*/}
						{/*			showProfile={stateCtx.profile}*/}
						{/*			local={*/}
						{/*				config.host*/}
						{/*					? stream.getId() === localStream && localStream.getId()*/}
						{/*					: false*/}
						{/*			}*/}
						{/*			key={`${index}${stream.getId()}`}*/}
						{/*			stream={stream}*/}
						{/*			isPlaying={stream.isPlaying()}*/}
						{/*			uid={stream.getId()}*/}
						{/*			domId={`stream-player-${stream.getId()}`}*/}
						{/*			onDoubleClick={handleDoubleClick}*/}
						{/*			showUid={true}*/}
						{/*		></StreamPlayer>*/}
						{/*	))}*/}
						{/*</div>*/}
					</div>
				</div>
				<div className="layout-player-aside">
					{/*<div className="layout-player-asideMain">*/}
					<div className="layout-player-chat-info">
						<TabContainer
							className={''}
							client={rtmClient}
							channel={config.channel}
							uid={config.uid}
							earlierTime={config.earlierTime}
							// onChange={changeEnabled}
							chatRecord={chatRecord}
						></TabContainer>
					</div>
					{/*<div className="camera"></div>*/}
					<div className="chat-bar">
						<ChatInput
							client={rtmClient}
							uid={config.uid}
							photo={image_photo()}
							nickName={nick_name()}
							permission={config.permission}
						/>
					</div>
					{/*</div>*/}
				</div>
			</div>
			
		</div>
	)
	
}

export default React.memo(RoomPage)