import React, {useEffect, useMemo, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {RandomRange} from "../utils/Random";
import {encryptKey} from "../utils/AESUtil";
import {useGlobalMutation, useGlobalState} from "../utils/container";
import useRouter from "../utils/use-router";
import VideoPlayer from "../utils/VideoPlayer";
import VideoCount from "./playback/VideoCount";
import ChatRecord from "./playback/ChatRecord";
import VideoPlayerPB from "./playback/VideoPlayerPB";

const useStyles = makeStyles({

})

const PlaybackPage = (props) => {
	const classes = useStyles()
	const routerCtx = useRouter()
	const stateCtx = useGlobalState()
	const mutationCtx = useGlobalMutation()
	
	// const [urlVideoList, setUrlVideoList] = useState([])
	const [urlVideoCR, setUrlVideoCR] = useState('')
	// const [urlVideoListMu, setUrlListMu] = useState([])
	const urlVideoList = useRef([])
	const urlVideo = useRef('')
	const roomId = useRef('')
	
	const config = useMemo(() => {
		return {
			channel: stateCtx.config.channelName
		}
	},[stateCtx])
	
	const history = routerCtx.history
	
	const handleChange = (newValue) => {
		console.log('new url:')
		console.log(newValue)
		setUrlVideoCR(newValue)
		urlVideo.current = newValue
	}
	
	useMemo(() => {
		let query = props.location.search
		if(query.indexOf('agoraRoomId') !== -1
			&& query.indexOf('userId') !== -1
			&& query.indexOf('cataId') !== -1){
			const arr = query.split('&')
			for(let j = 0; j < arr.length; j++){
				if(arr[j].indexOf('agoraRoomId') !== -1){
					var agoraRoomId = arr[j].split('=')[1]
					continue
				}
				if(arr[j].indexOf('cataId') !== -1){
					var cataId = arr[j].split('=')[1]
					continue
				}
				if(arr[j].indexOf('userId') !== -1){
					var userId = arr[j].split('=')[1]
					continue
				}
			}
			
			let formData = new FormData()
			let random = RandomRange(16,16)
			formData.append('access_token',encryptKey(random));
			formData.append('iv', random);
			formData.append('id', agoraRoomId);
			formData.append('userId', userId);
			formData.append('userType', '100');
			formData.append('expire', '7200');//失效时间（秒）
			fetch('https://atteacher.ixuesiwei.com/app/course/getCourseAccessPlayback',{
				method: 'post',
				body: formData
			})
				.then((res) => {
					return res.json()
				})
				.then((result) => {
					// console.log(result)
					roomId.current = agoraRoomId
					if(result.code === 200){
						// setUrlVideoList(result.result.listUrlVideo)
						const videoList = result.result.listUrlVideo
						urlVideoList.current = videoList
						// console.log('videolist')
						// console.log(result.result.listUrlVideo)
						urlVideo.current = videoList[0]
						// setUrlVideoMu(urlVideoList.current[0])
						mutationCtx.updateConfig({
							// uid: result.result.token.userId,
							channelName: result.result.token.channelName,
						})
						
					} else if (result.code === 500){
						throw new Error(result.message)
					}
				})
				.catch((err) => {
					console.error(err)
				})
		}
	},[])
	
	// useMemo(() => {
	// 	// setUrlListCR(urlVideoList.current)
	// 	// if(urlVideoList.length > 0){
	// 		setUrlVideoCR(urlVideoList[0])
	// 	// }
	// 	console.log('修改了某个值')
	// 	console.log(urlVideoList.current.length)
	// },[urlVideoList])
	//
	return (
		<div className="playback">
			<div className="layout-header-playback"></div>
			<div className="layout-player-playback">
				<div className="layout-player-main-playback">
					{urlVideoList.current.length > 0 ? (
						<div className="layout-player-info">
							<div className="layout-player-video-playback">
								<VideoPlayerPB src={urlVideo.current}></VideoPlayerPB>
							</div>
							<div className="layout-player-video-list">
								<div className="video-list-title">
									<div>回放分集</div>
								</div>
								<VideoCount data={urlVideoList.current} onChange={handleChange}></VideoCount>
							</div>
							<div ></div>
						</div>
					) : null}
					{/*<div className="layout-player-video-playback">*/}
					{/*	{urlVideoList ? (*/}
					{/*		<VideoPlayer src={urlVideo}></VideoPlayer>*/}
					{/*	) : null}*/}
					{/*</div>*/}
					{/*<div className="layout-player-video-list">*/}
					{/*		<VideoCount*/}
					{/*			data={urlVideoListMu}*/}
					{/*			onChange={handleChange}*/}
					{/*		/>*/}
					{/*</div>*/}
				</div>
				<div className="layout-player-aside-playback">
					<div className="layout-player-chat-record">
						<div className="chat-record-title">
							<div>历史记录</div>
						</div>
						<div className="chat-record-content">
							{roomId.current ? <ChatRecord roomId={roomId.current}></ChatRecord> : null}
						</div>
					</div>
				</div>
			</div>
			<div className="layout-footer-playback"></div>
		</div>
	)
}

export default React.memo(PlaybackPage)