import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouterHook } from './utils/use-router'
import { Route, Switch } from 'react-router-dom'
import Room from './pages/room'
import Live from './pages/live'
import Playback from "./pages/playback";
// import NoFoundPage from "./pages/404";

function App() {
	return (
		<BrowserRouterHook>
			<Switch>
				<Route exact name="直播间" path="/room" component={Room}></Route>
				<Route exact name="直播" path="/live" component={Live}></Route>
				<Route exact name="回放" path="/playback" component={Playback}></Route>
				{/*<Route exact path="*" component={NoFoundPage}></Route>*/}
			</Switch>
		</BrowserRouterHook>
	);
}

export default App;
