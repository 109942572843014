import React, {useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {useEffect} from "react";
import {Box, useTheme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import {RandomRange} from "../../utils/Random";
import {encryptKey} from "../../utils/AESUtil";
import Img from '../../utils/image'
import imgURL from '../../assets/avatar.png'
import {useGlobalMutation} from "../../utils/container";
import ContentList from "./ContentList";
import UserList from "./UseList";

function TabPanel(props) {
	const {children, value, index, ...other} = props
	
	return (
		<div
			className="tab-panel"
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				// <Box p={2}>
				// 	<Typography component={'span'} variant={'body2'}>{children}</Typography>
				// </Box>
				<div className="tab-panel-content">{children}</div>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
}

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	}
}

export default function TabContainer (props) {
	const theme = useTheme()
	const [value, setValue] = useState(0)
	const [earlierTime, setEarlierTime] = useState('')
	
	const mutationCtx = useGlobalMutation()

	const handleChange = (evt, newValue) => {
		setValue(newValue)
	}
	
	const handleChangeIndex = (index) => {
		setValue(index)
	}
	
	// const handleChangeTime = (evt) => {
	// 	evt.stopPropagation()
	// 	props.onChange(earlierTime)
	// }
	
	useMemo(() => {
		let formData = new FormData()
		let random = RandomRange(16,16)
		let earlierTime = props.earlierTime ? props.earlierTime : Date.now()
		// let earlierTime = 1594946574
		setEarlierTime(earlierTime)
		formData.append('access_token', encryptKey(random))
		formData.append('iv', random)
		formData.append('channel', props.channel)
		formData.append('ms', earlierTime.toString())
		formData.append('size', '100')
		fetch('https://atteacher.ixuesiwei.com/app/course/listMsg',{
			method: 'post',
			body: formData
		})
			.then((res) => {
				return res.json()
			})
			.then((result) => {
				if(result.code === 200){
					let userList = new Array()
					let messageList = new Array()
					result.result.map((chat) => {
						const str = chat.msg
						if( typeof str == 'string'){
							try {
								const obj = JSON.parse(str)
								if( typeof obj == 'object' && obj){
									let user = new Object()
									user.userId = chat.senderId
									userList.push(user)
									let msgObj = new Object()
									const msgStr = JSON.parse(str)
									msgObj.senderId = chat.senderId
									msgObj.photo = msgStr.photo
									msgObj.nickName = msgStr.userName
									msgObj.msg = msgStr.message
									messageList.push(msgObj)
								}else{
									console.error(chat.msg)
								}
							}catch (e) {
								console.log(e)
							}
						}
					})
					messageList.reverse()
					mutationCtx.setChatRecord(messageList)
				}else if(result.code === 500){
					throw new Error(result.message)
				}
			})
	},[props.config])
	
	return (
		<div className="tab-container">
			<AppBar position="static" color="default">
				<Tabs
					value={value}
					onChange={handleChange}
					// onChangeTime={handleChangeTime}
					indicatorColor="primary"
					textColor="primary"
					variant="fullWidth"
					aria-label="full width tabs example"
				>
					<Tab label="频道消息" {...a11yProps(0)} />
					<Tab label={"人员列表"+""} {...a11yProps(1)} />
				</Tabs>
			</AppBar>
			<SwipeableViews
				className="abcdef"
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={value}
				onChangeIndex={handleChangeIndex}
			>
				<TabPanel index={0} value={value} dir={theme.direction}>
					<ContentList
						data={props.chatRecord}
						uid={props.uid}/>
				</TabPanel>
				<TabPanel index={1} value={value} dir={theme.direction}>
					<UserList client={props.client}
						uid={props.uid}
						channel={props.channel}/>
				</TabPanel>
			</SwipeableViews>
		</div>
	)
}