import React, {useEffect, useRef, useState} from 'react'
import List from '@material-ui/core/List'
import {makeStyles} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import {Avatar} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import {RandomRange} from "../../utils/Random";
import {encryptKey} from "../../utils/AESUtil";

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper
	},
}))

export default function UserList (props) {
	const classes = useStyles
	const [userList, setUserList] = useState([])
	
	// const intervalRef = useRef<NodeJS.Timeout>('')
	// const getUserInfo = async () => {
	// 	const client = props.client
	// 	client.getMembers()
	// 		.then((list) => {
	// 			let userList = new Array()
	// 			list.map((id) => {
	// 				// list.filter(it => it !== props.uid).map((id) => {
	// 				let user = new Object()
	// 				user.userId = id
	// 				userList.push(user)
	// 			})
	// 			const random = RandomRange(16,16)
	// 			const accessToken = encodeURIComponent(encryptKey(random))
	// 			let url = 'https://atteacher.ixuesiwei.com/app/course/listUser'
	// 			url += '?access_token='+accessToken+'&iv='+random+'&channel='+props.channel
	// 			fetch(url,{
	// 				method: 'post',
	// 				headers: {
	// 					'Content-Type':'application/json;charset=UTF-8'
	// 				},
	// 				body: JSON.stringify(userList)
	// 			})
	// 				.then((res) => {
	// 					return res.json()
	// 				})
	// 				.then((result) => {
	// 					if(result.code === 200){
	// 						// console.error(result)
	// 						let userInfos = result.result
	// 						for(let i = 0; i < userInfos.length; i++){
	// 							const photoURL = userInfos[i].photo
	// 							if(photoURL.indexOf("http") === -1){
	// 								userInfos[i].photo = 'https://rescdn.ixuesiwei.com' + photoURL
	// 							}
	// 						}
	// 						setUserList(userInfos)
	// 					}
	// 				})
	// 		})
	// 		.catch(() => {
	// 			console.error('获取频道用户列表失败')
	// 		})
	// }
	
	// useEffect(() => {
	// 	const id =setInterval(() => {
	// 		getUserInfo()
	// 	}, 2 * 1000)
	// 	intervalRef.current = id
	// 	return () => {
	// 		if(intervalRef.current){
	// 			clearInterval(intervalRef.current)
	// 		}
	// 	}
	// })
	
	useEffect(() => {
		const client = props.client
		client.getMembers()
			.then((list) => {
				let userList = new Array()
				list.map((id) => {
				// list.filter(it => it !== props.uid).map((id) => {
						let user = new Object()
						user.userId = id
						userList.push(user)
				})
				const random = RandomRange(16,16)
				const accessToken = encodeURIComponent(encryptKey(random))
				let url = 'https://atteacher.ixuesiwei.com/app/course/listUser'
				url += '?access_token='+accessToken+'&iv='+random+'&channel='+props.channel
				fetch(url,{
					method: 'post',
					headers: {
						'Content-Type':'application/json;charset=UTF-8'
					},
					body: JSON.stringify(userList)
				})
					.then((res) => {
						return res.json()
					})
					.then((result) => {
						if(result.code === 200){
							// console.error(result)
							let userInfos = result.result
							for(let i = 0; i < userInfos.length; i++){
								const photoURL = userInfos[i].photo
								if(photoURL.indexOf("http") === -1){
									userInfos[i].photo = 'https://rescdn.ixuesiwei.com' + photoURL
								}
							}
							setUserList(userInfos)
						}
					})
			})
			.catch(() => {
				console.error('获取频道用户列表失败')
			})
	},[props])
	
	return (
		<div className="user-info-list">
			<div className="user-count">
				{"频道总人数："}
				<span>{userList.length}</span>
			</div>
			<div className="user-list">
				<List dense className={classes.root}>
					{userList.map(user => {
						return (
							<ListItem key={user.userId} button>
								<ListItemAvatar>
									<Avatar
										alt={user.nickName}
										src={user.photo} />
								</ListItemAvatar>
								<ListItemText primary={user.nickName} />
							</ListItem>
						)
					})}
				</List>
			</div>
		</div>
	)
}