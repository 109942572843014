import React, {createContext, useContext, useReducer, useState, useEffect} from 'react'
import { reducer } from './reducers'
import { defaultState } from "./actionType";
import CustomizedSnackbar from './snackbar-wrapper'
import Loading from './loading'

const StateContext = createContext({})
const MutationContext = createContext({})

export const ContainerProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, defaultState)
	
	window.rootState = state
	
	const [toasts, updateToasts] = useState([])
	
	const methods = {
		startLoading () {
			dispatch({ type: 'loading', payload: true })
		},
		stopLoading () {
			dispatch({ type: 'loading', payload: false })
		},
		updateConfig (params) {
			dispatch({ type: 'config', payload: { ...state.config, ...params } })
		},
		setClient (clientInstance) {
			dispatch({ type: 'client', payload: clientInstance })
		},
		setCodec (param) {
			dispatch({ type: 'codec', payload: param })
		},
		setVideo (param) {
			dispatch({ type: 'video', payload: param })
		},
		setAudio (param) {
			dispatch({ type: 'audio', payload: param })
		},
		setScreen (param) {
			dispatch({ type: 'screen', payload: param })
		},
		setProfile (param) {
			dispatch({ type: 'profile', payload: param })
		},
		toastSuccess (message) {
			updateToasts([
				...toasts,
				{
					variant: 'success',
					message
				}
			])
		},
		toastInfo (message) {
			updateToasts([
				...toasts,
				{
					variant: 'info',
					message
				}
			])
		},
		toastError (message) {
			updateToasts([
				...toasts,
				{
					variant: 'error',
					message
				}
			])
		},
		removeTop () {
			const items = toasts.filter((e, idx) => idx > 0)
			updateToasts([...items])
		},
		setLocalStream (param) {
			dispatch({ type: 'localStream', payload: param })
		},
		setCurrentStream (param) {
			dispatch({ type: 'currentStream', payload: param })
		},
		setDevicesList (param) {
			dispatch({ type: 'devicesList', payload: param })
		},
		clearAllStream () {
			dispatch({ type: 'clearAllStream' })
		},
		addLocal (evt) {
			const { stream } = evt
			methods.setLocalStream(stream)
			methods.setCurrentStream(stream)
		},
		addStream (evt) {
			const { stream } = evt
			dispatch({ type: 'addStream', payload: stream })
		},
		removeStream (evt) {
			const { stream } = evt
			dispatch({ type: 'removeStream', stream: stream })
		},
		removeStreamById (evt) {
			const { uid } = evt
			dispatch({ type: 'removeStream', uid: uid })
		},
		connectionStateChanged (evt) {
			methods.toastInfo(`${evt.curState}`)
		},
		// enableBeauty(enable) {
		//   dispatch({type: 'enableBeauty', enable});
		// }
		//rtm实时消息
		setRTMClient (param) {
			dispatch({ type: 'rtmClient', payload: param})
		},
		setChatRecord (param) {
			dispatch({ type: 'chatRecord', payload: param})
		},
		setEarlierTime (param) {
			dispatch({ type: 'earlierTime', payload: param})
		},
		rtmConnectionStateChanged (newState, reason) {
			console.log(`newState: ${newState}, reason: ${reason}`)
		},
		sendLocalMessage(text, uid ){
			dispatch({ type: 'addMessage', payload: {
				text: text, senderId: uid }})
		},
		receiveMessage(text, memberId ){
			//处理接收到的频道消息
			// console.log(`text: ${text}, memeberId: ${memberId}`)
			dispatch({ type: 'addMessage', payload: {text: text, senderId: memberId}})
		},
		updateMemberCount (memberCount){
			//处理当前频道的人数
			console.log(`Count: ${memberCount}`)
		},
		addMember (memberId) {
			//处理加入频道的用户（512人以内）
			console.log(`member: ${memberId} join channel`)
		},
		removeMember (memberId) {
			//处理离开频道的用户（512人以内）
			console.log(`member: ${memberId} leave`)
		}
	}
	
	useEffect(() => {
		window.sessionStorage.setItem(
			'custom_storage',
			JSON.stringify({
				uid: state.config.uid,
				host: state.config.host,
				channelName: state.config.channelName,
				// token: state.config.token,
				rtcToken: state.config.rtcToken,
				rtmToken: state.config.rtmToken,
				resolution: state.config.resolution,
				url: state.config.url,
				earlierTime: state.config.earlierTime
			})
		)
	}, [state])
	
	return (
		<StateContext.Provider value={state}>
			<MutationContext.Provider value={methods}>
				<CustomizedSnackbar toasts={toasts} />
				{state.loading ? <Loading /> : null}
				{children}
			</MutationContext.Provider>
		</StateContext.Provider>
	)
}

export function useGlobalState () {
	return useContext(StateContext)
}

export function useGlobalMutation () {
	return useContext(MutationContext)
}
