import React, {useEffect, useMemo, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import RTMClient from "../utils/rtm-client";
import {RandomRange} from "../utils/Random";
import {encryptKey} from "../utils/AESUtil";
import VideoPlayer from "../utils/VideoPlayer";
import 'video.js/dist/video-js.min.css';
import TabContainer from "./room/TabContainer";
import ChatInput from "./room/ChatInput";
import useMessage from "../utils/use-message";
import {useGlobalMutation, useGlobalState} from "../utils/container";
import useRouter from "../utils/use-router";
import VideoPlayerL from "./live/VideoPlayerL";

const useStyles = makeStyles({

})

const LivePage = (props) => {
	const classes = useStyles()
	const routerCtx = useRouter()
	const stateCtx = useGlobalState()
	const mutationCtx = useGlobalMutation()
	
	const rtmClient = useMemo(() => {
		const client = new RTMClient()
		if(!client._created){
			client.createClient()
			client._created = true
		}
		return client
	},[])
	
	const [urlPull, setUrlPull] = useState()
	const urlPullRef = useRef('')
	const nickName = useRef('')
	const photo = useRef('')
	
	const [nickNameMu, setNickNameMu] = useState('')
	const [photoMu, setPhotoMu] = useState('')
	
	const chatRecord = useMessage(rtmClient)
	
	const config = useMemo(() => {
		return {
			rtmToken: stateCtx.config.rtmToken,
			channel: stateCtx.config.channelName,
			uid: stateCtx.config.uid,
			permission: stateCtx.config.permission,
		}
	},[stateCtx])
	
	const history = routerCtx.history
	
	useMemo(() => {
		let query = props.location.search
		if(query.indexOf('agoraRoomId') !== -1
			&& query.indexOf('userId') !== -1
			&& query.indexOf('cataId') !== -1){
			const arr = query.split('&')
			for(let j = 0; j < arr.length; j++){
				if(arr[j].indexOf('agoraRoomId') !== -1){
					var agoraRoomId = arr[j].split('=')[1]
					continue
				}
				if(arr[j].indexOf('cataId') !== -1){
					var cataId = arr[j].split('=')[1]
					continue
				}
				if(arr[j].indexOf('userId') !== -1){
					var userId = arr[j].split('=')[1]
					continue
				}
			}
			
			let formData = new FormData()
			let random = RandomRange(16,16)
			formData.append('access_token',encryptKey(random));
			formData.append('iv', random);
			formData.append('id', agoraRoomId);
			formData.append('userId', userId);
			formData.append('userType', '100');
			formData.append('expire', '7200');//失效时间（秒）
			fetch('https://atteacher.ixuesiwei.com/app/course/getCourseAccess',{
				method: 'post',
				body: formData
			})
				.then((res) => {
					return res.json()
				})
				.then((result) => {
					if(result.code === 200){
						// setUrlPull(result.result.urlPullHls)
						urlPullRef.current = result.result.urlPullHls
						// console.log(result.result.urlPullHls)
						mutationCtx.updateConfig({
							uid: result.result.token.userId,
							channelName: result.result.token.channelName,
							rtmToken: result.result.token.rtmToken,
							permission: result.result.permission,
						})
						let formData = new FormData()
						let random = RandomRange(16,16)
						formData.append('access_token',encryptKey(random));
						formData.append('iv', random);
						formData.append('id',userId)
						fetch('https://atteacher.ixuesiwei.com/app/course/getStuInfo',{
							method: 'post',
							body: formData
						})
							.then((res) => {
								return res.json()
							})
							.then((result) => {
								// const ava = result.result.photo
								let photoURL = result.result.photo
								if(photoURL.indexOf("http") === -1){
									photoURL = 'https://rescdn.ixuesiwei.com' + photoURL
								}
								const name = result.result.nickName
								// console.log('add nickName')
								nickName.current = name
								photo.current = photoURL
								// setNickName(name)
								// setPhoto(photoURL)
								// console.log('set nickName photo success')
							})
							.catch((err) => {
								console.error(err)
							})
					} else if(result.code === 500){
						throw new Error(result.message)
					}
				})
				.catch((err) => {
					console.error(err)
				})
		}
	},[])
	
	useMemo(() => {
		if( config.channel && rtmClient._created && rtmClient._joined === false){
			rtmClient
				.join(config)
				.then(() => {
					// rtmClient.setLocalUserAttributes(photo.current,nickName.current)
					// 	.then((uid) => {
					// 		setNickNameMu(nickName.current)
					// 		setPhotoMu(photo.current)
					// 		console.log(uid+' set attributes success')
					// 	})
					// 	.catch((err) => {
					// 		console.error(err)
					// 	})
					mutationCtx.stopLoading()
				})
				.catch((err) => {
					console.error(err)
				})
		}
	},[rtmClient, config, routerCtx])
	
	// const nick_name = useMemo(() => {
	// 	return () => nickName.current
	// },[nickName])
	//
	// const image_photo = useMemo(() => {
	// 	return () => photo.current
	// },[photo])

	// useEffect(() => {
	// 	setNickNameMu(nickName.current)
	// 	setPhotoMu(photo.current)
	// },[nickName, photo])
	
	return (
		<div className="live">
			{/*<div className="layout-main-live">*/}
				<div className="layout-header-live"></div>
				<div className="layout-player-live">
					<div className="layout-player-main-live">
						{/*<div className="layout-player-title">*/}
						{/*	<div className="live-title"></div>*/}
						{/*</div>*/}
						<div className="layout-player-video">
							{/*{urlPull ? (*/}
							{/*	<VideoPlayer src={urlPull}></VideoPlayer>*/}
							{/*): null}*/}
							{urlPullRef.current !== '' ? (
								<VideoPlayerL src={urlPullRef.current}></VideoPlayerL>
							 ) : null }
						</div>
					</div>
					<div className="layout-player-aside-live">
						{/*<div className="layout-player-asideMain-live">*/}
							<div className="layout-player-chat-info">
								<TabContainer
									className={''}
									client={rtmClient}
									channel={config.channel}
									uid={config.uid}
									earlierTime={config.earlierTime}
									// onChange={changeEnabled}
									chatRecord={chatRecord}
								></TabContainer>
							</div>
							<div className="chat-bar">
								<ChatInput
									client={rtmClient}
									uid={config.uid}
									photo={photo.current}
									nickName={nickName.current}
									permission={config.permission}
								/>
							</div>
						{/*</div>*/}
					</div>
				</div>
				
			{/*</div>*/}
		</div>
	)
}

export default React.memo(LivePage)