import React, {useEffect, useRef} from "react";
import Img from "../../utils/image";
import imgURL from "../../assets/avatar.png";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	me:{
		// color: 'red',
		color: '#F13A1D'
	},
	other:{
		// color: 'blue'
		color: '#2B94FF'
	},
	content: {
		color: '#333',
	}
}))

export default function ContentList (props){
	const classes = useStyles()
	const messagesEndRef = useRef(null)
	
	const scrollToBottom = () => {
		messagesEndRef.current.scrollIntoView({ behavior: "smooth"})
	}
	
	useEffect(scrollToBottom,[props])
	const list = props.data.map((info,index) =>
		<li key={index}>
			<div>
			<span>
				<Img imageUrl={info.photo} defaultImg={imgURL}></Img>
			</span>
				<span className={info.senderId === props.uid ? classes.me : classes.other }>{info.nickName}:</span>
				<span className={classes.content}>{info.msg}</span>
			</div>
		</li>
	)
	return (
		<div className="message-content">
			<div>
				<ul>{list}</ul>
				<div ref={messagesEndRef} />
			</div>
		</div>
	)
}