import React from 'react'
import videojs from 'video.js'
import { HlsSourceHandler } from 'videojs-contrib-hls'
import 'video.js/dist/video-js.min.css'

export default class VideoPlayerPB extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			videoJsOptions: {
				preload: 'auto',
				autoplay: true,
				controls: true,
				fluid: true,
				playbackRates: [0.5, 1 , 2],
				sources: [{
					// src: 'http://vjs.zencdn.net/v/oceans.mp4',
					src: this.props.src,
				}]
			}
		}
		this.player = null
	}
	
	componentDidMount() {
		videojs.getTech('html5').registerSourceHandler(HlsSourceHandler('html5'),0)
		let _this = this
		this.player = videojs(this.videoContainer,
			{...this.state.videoJsOptions}, function () {
				let props = _this.props
				
				if(!!props.onReady){
					props.onReady(props)
				}
			}
		)
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const src = this.props.src
		if(!!this.player){
			this.player.ready(function () {
				this.src(src)
				this.load()
			})
		}
	}
	
	componentWillUnmount() {
		if(this.player){
			this.player.dispose()
		}
	}
	
	render() {
		return (
			<div className="video-playback">
				<video ref={node => this.videoContainer = node} className="video-js vjs-16-9"></video>
			</div>
		)
	}
}